export default function IndoorContent() {
  return (
    <div className="indoorDiv">
      <h1 className="indoorTitle">
      
        GAGNEZ EN PRECISION ET EN EFFICACITE DANS VOS PROJETS INDOOR
      </h1>
      <p className="indoorText">
        Lorsqu'il s'agit d'aménager des espaces indoor, la précision est
        essentielle.
      </p>
      <p className="indoorText">
        Faire appel à GeomXpert vous permet de bénéficier d'un service de prise
        de mesures expertes, de dossiers de commande complets et d'un soutien
        technique pour une installation sans souci.
      </p>
      <p className="indoorText">
        Économisez du temps et des efforts, tout en obtenant des solutions
        personnalisées pour vos besoins spécifiques.
      </p>
      <p className="indoorText closingArgumentText">
        Optez pour GeomXpert pour optimiser vos projets indoor.
      </p>
    </div>
  );
}
