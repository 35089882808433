import StudySteps from '../components/yourstudy/yourstudycomponent';

export default function YourStudy() {
  return (
    <div className='indoorSection'>
      <h1 className="yourStudyTitle">VOTRE ETUDE EN DETAILS:</h1>
      <StudySteps />
    </div>
  );
}
