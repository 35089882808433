import logo from '../../images/noname_black_background_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faX,
  faPhone,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
  const [open, setOpen] = useState(false);
  let classFa;
  // let collapseDisplay;
  if (open === false) {
    classFa = <FontAwesomeIcon icon={faBars} />;
    // collapseDisplay = 'none';
  } else {
    classFa = <FontAwesomeIcon icon={faX} />;
    // collapseDisplay = null;
  }
  return (
    <header className="header">
      <div className="phoneAndMailDiv">
        <p className="desktopMailPhone">
          E-mail :{' '}
          <a href="mailto:contact@geomxpert.fr">contact@geomxpert.fr</a> || Tel.
          : <a href="tel:0622971582">06 22 97 15 82</a>
        </p>
        <p className="mobileMailPhone">
          <a href="mailto:contact@geomxpert.fr">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>{' '}
          ||
          <a href="tel:0622971582">
            <FontAwesomeIcon icon={faPhone} />
          </a>
        </p>
      </div>
      <div className="navAndLogoDiv">
        <div className="bannerLogoDiv">
          <a href="/">
            <img
              className="bannerLogo"
              src={logo}
              alt="GeomXpert"
            />
          </a>
        </div>
        <nav className="nav">
          <ul className="desktopNavList">
            <li>
              <Link to="/">ACCUEIL</Link>
            </li>

            <li>
              <Link to="/indoor">INDOOR </Link>
            </li>

            <li>
              <Link to="/outdoor">OUTDOOR </Link>
            </li>

            <li>
              <Link to="/votreetude">VOTRE ETUDE </Link>
            </li>
          </ul>
        </nav>
        <nav className="mobileNav">
          {' '}
          <div
            className={`collapsibleDiv}`}
            onMouseLeave={() => setOpen(false)}
          >
            <button
              className="collapseTitle collapseBtn"
              onClick={() => setOpen(!open)}
            >
              {classFa}
            </button>

            <div
              className={`collapseContent ${open ? 'active' : ''}`}
              // style={{ display: collapseDisplay }}
            >
              <ul className="mobileNavList">
                <li>
                  <Link
                    to="/"
                    onClick={() => setOpen(!open)}
                  >
                    ACCUEIL
                  </Link>
                </li>

                <li>
                  <Link
                    to="/indoor"
                    onClick={() => setOpen(!open)}
                  >
                    INDOOR{' '}
                  </Link>
                </li>

                <li>
                  <Link
                    to="/outdoor"
                    onClick={() => setOpen(!open)}
                  >
                    OUTDOOR{' '}
                  </Link>
                </li>

                <li>
                  <Link
                    to="/votreetude"
                    onClick={() => setOpen(!open)}
                  >
                    VOTRE ETUDE{' '}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
