import { Routes, Route } from 'react-router-dom';
// import { createContext, useState, useEffect } from 'react';
import HomePage from './pages/homepage';
import Indoor from './pages/indoorpage';
import Outdoor from './pages/outdoorpage';
import YourStudy from './pages/yourstudypage.jsx';
import NotFound from './pages/404';
import Header from './components/header/banner';
import Footer from './components/footer/footer';
import LegalMention from './pages/legalpage';
import ContactForm from './components/contact/contactform';
import './style/desktop.css';
import './style/tablet.css';
import './style/mobile.css';

// export const AuthContext = createContext();

export default function App() {
  return (
    <div className="app">
      <Header />
      <Routes>
        <Route
          path="/"
          element={<HomePage />}
        />
        <Route
          path="/indoor"
          element={<Indoor />}
        />
        <Route
          path="/outdoor"
          element={<Outdoor />}
        />
        <Route
          path="/votreetude"
          element={<YourStudy />}
        />
        <Route
          path="/mentionslegales"
          element={<LegalMention />}
        />
        <Route
          path="/*"
          element={<NotFound />}
        />
      </Routes>
      <ContactForm />
      <Footer />
      <div className="background"></div>
    </div>
  );
}
