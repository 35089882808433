export default function NotFound() {
  return (
    <div>
      <h1>Erreur 404.</h1>
      <h1>
        Désolé, la page que vous recherchez n'existe pas.{' '}
        <a href="/">Reprendre la navigation.</a>
      </h1>
    </div>
  );
}
