import HomeCarousel from '../components/common/homecarousel';
import HomeContent from '../components/home/homecomponent';
import home1 from '../images/home/home1.png';
import home2 from '../images/home/home2.png';
import home3 from '../images/home/home3.png';

export default function HomePage() {
  const images = [home1, home2, home3];
  const text = [
    'Métreur indépendant pour les artisans',
    'Pour les PMEs',
    'Pour les Entreprises Générales',
  ];
  console.log(text);
  return (
    <div>
      <HomeCarousel
        images={images}
        text={text}
      />{' '}
      <HomeContent />
    </div>
  );
}
