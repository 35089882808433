import React from 'react';

export default function LegalComponent() {
  return (
    <div className="legalMentionDiv">
      <h1>MENTIONS LEGALES</h1>
      <p>En vigueur au 31/08/2023</p>
      <p>
        Conformément aux dispositions des Articles 6-III et 19 de la Loi
        n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique,
        dite L.C.E.N., il est porté à la connaissance des utilisateurs et
        visiteurs, ci-après l""Utilisateur", du site www.geomxpert.fr , ci-après
        le "Site", les présentes mentions légales.
      </p>
      <p>
        La connexion et la navigation sur le Site par l’Utilisateur impliquent
        acceptation intégrale et sans réserve des présentes mentions légales.
      </p>
      <h2>ARTICLE 1 - L'EDITEUR</h2>
      <p>
        L’édition et la direction de la publication du Site est assurée par
        ANAÏS2L SARL, domiciliée au Centre Commercial de la Perussonne 1465 rn8
        13400 Aubagne dont l'adresse e-mail est anais2l@gmail.com.
      </p>
      <p>
        La personne est assujettie au Registre du Commerce et des Sociétés de
        Marseille sous le numéro 900 318 700.{' '}
      </p>

      <h2>ARTICLE 2 - L'HEBERGEUR</h2>
      <p>
        L'hébergeur du Site est la société OVH SAS au capital de 10 174 560 €,
        dont le siège social est situé au 2 rue Kellermann - 59100 Roubaix -
        France.{' '}
      </p>
      <h2>ARTICLE 3 - ACCES AU SITE</h2>
      <p>
        Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force
        majeure, interruption programmée ou non et pouvant découlant d’une
        nécessité de maintenance. En cas de modification, interruption ou
        suspension du Site, l'Editeur ne saurait être tenu responsable.
      </p>
      <h2>ARTICLE 4 - COLLECTE DES DONNEES</h2>
      <p>
        Le site est exempté de déclaration à la Commission Nationale
        Informatique et Libertés (CNIL) dans la mesure où il ne collecte aucune
        donnée concernant les utilisateurs.
      </p>
      <p>
        Toute utilisation, reproduction, diffusion, commercialisation,
        modification de toute ou partie du Site, sans autorisation de l’Editeur
        est prohibée et pourra entraîner des actions et poursuites judiciaires
        telles que notamment prévues par le Code de la propriété intellectuelle
        et le Code civil.
      </p>
    </div>
  );
}
