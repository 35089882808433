import logo from '../../images/name_transp_logo.png';

export default function HomeContent() {
  return (
    <div className="homeContentDiv">
      <div className="introDiv">
        <h1 className="homeTitle">GeomXpert</h1>
        <h2 className="homeTitle">
         VOTRE PARTENAIRE DE CONFIANCE DANS L'AMENAGEMENT INDOOR ET OUTDOOR
        </h2>
        <img
          src={logo}
          className="homeLogo"
          alt="GeomXpert - Partenaire de Confiance"
        ></img>
        <p className="homeText">
          Dans l'univers de l'aménagement indoor et outdoor, la qualité, la
          précision et le service irréprochable sont indispensables pour créer
          des espaces de vie exceptionnels. C'est pourquoi nous sommes ravis de
          vous présenter GeomXpert, une entreprise fondée sur une expérience
          solide et une passion pour la perfection.
        </p>
        <p className="homeText">
          Avec plus de 5 ans d'expérience en tant que responsable techniques
          chez un fournisseur renommé, nous avons acquis une connaissance
          approfondie du secteur de l'aménagement indoor et outdoor. Forts de
          cette expertise, nous avons décidé de créer notre propre entreprise
          pour offrir un service unique et essentiel aux professionnels du
          domaine.
        </p>{' '}
      </div>
      <div className="homeDetailsDiv">
        <h2 className="homeTitle">NOS SERVICES</h2>
        <p className="homeText">
          {' '}
          Grâce à des années d'expérience, nous savons à quel point des mesures
          précises sont cruciales pour garantir que les installations s'adaptent
          parfaitement à leur environnement. Nous proposons des services de
          prise de mesures qui garantissent une précision absolue.
        </p>{' '}
        <div className="homeServicesDiv">
          {' '}
          <div className="homeDetailsSubDiv">
            <h3 className="homeTitle">
              {' '}
              CONSTITUTION DE DOSSIER DE COMMANDE :{' '}
            </h3>
            <p className="homeText">
              Notre équipe compétente est là pour simplifier le processus pour
              les vendeurs, en constituant des dossiers de commande complets,
              éliminant ainsi les erreurs potentielles et les malentendus.
            </p>{' '}
          </div>{' '}
          <div className="homeDetailsSubDiv">
            <h3 className="homeTitle">
           MISE EN PLACE DE CHANTIER EN APPUI TECHNIQUE :
            </h3>
            <p className="homeText">
              Nous offrons un service de soutien technique pour les poseurs,
              garantissant une installation sans faille. Votre satisfaction et
              la durabilité de votre projet sont nos priorités.
            </p>
          </div>
        </div>
      </div>
      <div className="homeDetailsDiv">
        <h2 className="homeTitle">POURQUOI NOUS CHOISIR</h2>
        <div className="homeServicesDiv">
          <div className="homeDetailsSubDiv">
            {' '}
            <h3 className="homeTitle">EXPERIENCE INEGALEE: </h3>
            <p className="homeText">
              Avec une expérience de travail direct dans l'industrie de
              l'aménagement indoor et outdoor, nous possédons une expertise
              inestimable.
            </p>{' '}
          </div>
          <div className="homeDetailsSubDiv">
            <h3 className="homeTitle">PARTENAIRE FIABLE : </h3>
            <p className="homeText">
              {' '}
              Nous sommes engagés à fournir des services de haute qualité, à
              respecter les délais et à satisfaire pleinement nos clients.
            </p>{' '}
          </div>
          <div className="homeDetailsSubDiv">
            {' '}
            <h3 className="homeTitle"> SOLUTIONS PERSONNALISEES : </h3>
            <p className="homeText">
              Chaque projet est unique, et nous adaptons nos services pour
              répondre aux besoins spécifiques de nos clients professionnels.
            </p>
          </div>
        </div>
        <div className="closingArgument">
          <p className="closingArgumentText">
            Si vous recherchez un partenaire de confiance pour vous accompagner
            dans vos projets d'aménagement indoor et outdoor, GeomXpert est là
            pour vous. Contactez-nous dès aujourd'hui pour discuter de vos
            besoins et découvrez comment nous pouvons simplifier et améliorer
            vos opérations.
          </p>
        </div>
      </div>
    </div>
  );
}
