export default function OutdoorContent() {
  return (
    <div className="indoorDiv">
      <h1 className="indoorTitle">
TRANSFORMEZ VOS ESPACES OUTDOOR AVEC GEOMXPERT
      </h1>
      <p className="indoorText">
        Lorsque vous souhaitez aménager des espaces outdoor exceptionnels, la
        précision et l'efficacité sont essentielles.
      </p>
      <p className="indoorText">
        Avec GeomXpert, vous bénéficiez de prises de mesures expertes, de
        dossiers de commande sans erreur et d'un soutien technique pour une
        installation sans souci.
      </p>
      <p className="indoorText">
        Économisez du temps et des efforts tout en obtenant des solutions sur
        mesure pour vos projets en plein air.
      </p>
      <p className="indoorText closingArgumentText">
        Choisissez GeomXpert pour sublimer vos espaces outdoor.
      </p>
    </div>
  );
}
