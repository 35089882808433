import { API_ROUTES } from '../../data/constants';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
export default function ContactForm() {
  const formTitle = 'Demandez un Devis !';
  const thirdInput = 'Votre Demande';
  const contactType = 'Demande de Devis';
  const [images, setImages] = useState([]);
  const [imagesFiles, setImagesFiles] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const HandleSubmit = async (event) => {
    event.preventDefault();

    const email = event.target.email.value;
    const contactPhone = event.target.tel.value
      ? event.target.tel.value
      : 'non renseigné';
    const name = event.target.name.value;
    const body = event.target.body.value;
    const consent = event.target.consent.checked;
    const prenom = event.target.prenom.value
      ? event.target.prenom.value
      : 'non renseigné';
    const siren = event.target.siren.value
      ? event.target.siren.value
      : 'non renseigné';
    const zipcode = event.target.zipcode.value
      ? event.target.zipcode.value
      : 'non renseigné';
    const city = event.target.city.value
      ? event.target.city.value
      : 'non renseigné';

    const formData = new FormData();
    formData.append('email', email);
    formData.append('name', name);
    formData.append('contactPhone', contactPhone);
    formData.append('body', body);
    formData.append('contactType', contactType);
    formData.append('consent', consent);
    formData.append('prenom', prenom);
    formData.append('siren', siren);
    formData.append('zipcode', zipcode);
    formData.append('city', city);
    imagesFiles.forEach((file, index) => {
      formData.append('files[' + index + ']', file);
    });
    console.log(formData);
    const PostMessage = await fetch(`${API_ROUTES.CONTACT}`, {
      method: 'POST',
      body: formData,
    });

    const data = await PostMessage.json();

    if (!PostMessage.ok) {
      throw new Error(data.message);
    }
    console.log(data);
    return MessageSent();
  };

  const HandleImageChange = (e, files = e.target.files) => {
    let imagesArr = [...images];
    let imagesFilesArr = [...imagesFiles];
    let loadedFilesCount = 0;
    let totalSize = imagesFiles.reduce((acc, file) => acc + file.size, 0);

    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].size;
    }

    if (totalSize > 10 * 1024 * 1024) {
      // 10 MB in bytes
      alert('Le poids total des pièces jointes ne peut dépasser 10Mo.');
      return;
    }
    for (let i = 0; i < files.length; i++) {
      imagesFilesArr.push(files[i]);
      let reader = new FileReader();
      // eslint-disable-next-line
      reader.onloadend = () => {
        imagesArr.push({
          src: reader.result,
          type: files[i].type,
          name: files[i].name,
        });
        loadedFilesCount++;
        if (loadedFilesCount === files.length) {
          setImages(imagesArr);
          setImagesFiles([...imagesFilesArr]);
        }
      };
      reader.readAsDataURL(files[i]);
    }
  };

  const HandleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    HandleImageChange(event, files);
  };
  const HandleDragOver = (event) => {
    event.preventDefault();
  };
  return (
    <div className="formDiv">
      <h1 className={`formTitle`}>{`${formTitle}`}</h1>
      <form
        className="form"
        onSubmit={HandleSubmit}
      >
        <h2>Vos Informations</h2>
        <div className="formElementDiv">
          <label for="email">Email*</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="exemple@email.fr"
            required
          ></input>
        </div>
        <div className="formElementDiv">
          <label for="tel">Téléphone</label>
          <input
            type="tel"
            name="tel"
            id="tel"
            placeholder="01 02 03 04 05"
          ></input>
        </div>
        <div className="formElementDiv">
          <label for="name">Nom*</label>
          <input
            type="text"
            name="name"
            id="name"
            required
          ></input>
        </div>
        <div className="formElementDiv">
          <label for="prenom">Prénom*</label>
          <input
            type="text"
            name="prenom"
            id="prenom"
            required
          ></input>
        </div>
        <div className="formElementDiv">
          <label for="siren">SIREN</label>
          <input
            type="text"
            name="siren"
            id="siren"
          ></input>
        </div>
        <div className="infosChantier">
          <h2>Informations Chantier</h2>
          <div className="formElementDiv">
            <label for="zipcode">Code Postal*</label>
            <input
              type="text"
              name="zipcode"
              id="zipcode"
              onChange={(e) => FetchAddressInfo(e.target.value, setCities)}
              required
            ></input>
          </div>
          {cities.length > 0 && (
            <div className="formElementDiv">
              <label for="city">Ville</label>
              <select
                name="city"
                id="city"
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
              >
                {cities.map((city, index) => (
                  <option
                    key={index}
                    value={city}
                  >
                    {city}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="formElementDiv">
            <label for="body">Votre Demande*</label>
            <textarea
              name="body"
              id="body"
              required
              className={`${thirdInput}`}
            ></textarea>
          </div>
        </div>
        <div
          className="dropzone"
          id="dropzone"
          onDrop={HandleDrop}
          onDragOver={HandleDragOver}
        >
          <div className="addImgGrid">
            {images.map((image, index) => (
              <div
                key={index}
                className="addedImgItemDiv"
              >
                {image.type === 'application/pdf' ? (
                  <div className="addedImgItem">
                    <FontAwesomeIcon
                      className="addedPdfIcon"
                      icon={faFile}
                      alt="uploaded"
                    />
                    <p className="addedPdfName">{image.name}</p>
                  </div>
                ) : (
                  <img
                    src={image.src}
                    alt="uploaded"
                    className="addedImgItem"
                  />
                )}
                <button
                  className="deleteImgButton"
                  onClick={() =>
                    HandleDeleteImage(
                      index,
                      setImages,
                      setImagesFiles,
                      images,
                      imagesFiles
                    )
                  }
                >
                  Supprimer
                </button>
              </div>
            ))}
          </div>
          <label
            className="addImgLabel"
            for="imageInput"
          >
            <p>+ Ajouter des Documents </p>
            <p>formats acceptés : PDF, JPG, PNG</p>
            <input
              type="file"
              accept="image/png, image/jpeg, application/pdf"
              name="files"
              id="imageInput"
              onChange={HandleImageChange}
            ></input>
          </label>
        </div>
        <label
          for="consent"
          className="consentBox"
        >
          <input
            type="checkbox"
            className="dataConsent"
            id="consent"
            name="consent"
          ></input>
          <p className="dataConsentText">
            J'autorise GeomXpert à conserver mes coordonnées pour m'informer des
            dernières nouvelles et offres commerciales.
          </p>
        </label>
        <input
          type="submit"
          value="Envoyer"
          className="sendBtn"
        ></input>
        <p className="legalInfo">
          Conformément à la loi Informatique et Libertés du 6 Janvier 1978,
          modifiée par la loi du 6 Aout 2004, vous disposez d’un droit d’accès,
          de modification, de rectification et de suppression des données qui
          vous concernent (art. 34 de la loi “Informatique et Libertés”). Les
          informations recueillies sur ce formulaire sont enregistrées dans une
          base de données de GeomXpert pour son propre compte. Elles seront
          exclusivement utilisées pour répondre à l’objet de votre demande et
          seront conservées si nécessaire.
        </p>
      </form>
    </div>
  );
}
function MessageSent() {
  console.log('le message a été envoyé');
  const contactForm = document.getElementsByClassName('form')[0];
  const contactDiv = contactForm.parentElement;

  contactForm.remove();
  const thankYouNote = document.createElement('div');
  thankYouNote.setAttribute('class', 'messageSent');
  thankYouNote.innerHTML = `<p>Merci pour votre contact. Nous reviendrons vers vous au plus tôt.</p> <a class="linkBackHome" href="/">Retourner à l'accueil</a>`;
  contactDiv.appendChild(thankYouNote);
}

const FetchAddressInfo = async (zipcode, setCities) => {
  if (zipcode.length > 2) {
    // eslint-disable-next-line
    const response = await fetch(`${API_ROUTES.ADDRESS}?zipcode=${zipcode}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setCities(response);
      })
      .catch((error) => console.error('Error:', error));
  }
};

const HandleDeleteImage = (
  index,
  setImages,
  setImagesFiles,
  images,
  imagesFiles
) => {
  const newImages = [...images];
  newImages.splice(index, 1);
  setImages(newImages);

  const newImagesFiles = [...imagesFiles];
  newImagesFiles.splice(index, 1);
  setImagesFiles(newImagesFiles);
};
