import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faReceipt,
  faScrewdriverWrench,
  faFileInvoiceDollar,
  faDraftingCompass,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';

const steps = [
  {
    title: '1. PRISE DE CONTACT',
    description:
      'Premier entretien de définition du projet, par téléphone, mail, réunion…',
    icon: faPhone,
  },
  {
    title: '2. RECEPTION DES ELEMENTS',
    description:
      'Récupération de vos documents et de votre demande détaillée : plans, photos, croquis…',
    icon: faReceipt,
  },
  {
    title: '3. PROPOSITION COMMERCIALE',
    description:
      'Suite à la réception de vos documents et à notre premier entretien vous recevrez une proposition commerciale détaillée et complète de nos services.',
    icon: faFileInvoiceDollar,
  },
  {
    title: '4. RENDEZ-VOUS SUR CHANTIER',
    description:
      'Déplacement de la société GeomXpert sur site, mesure et étude',
    icon: faDraftingCompass,
  },
  {
    title: '5. CONSTITUTION DU DOSSIER DE COMMANDE',
    description:
      'Report de la prise de côtes sur le formulaire de commande de votre fournisseur',
    icon: faFileAlt,
  },
  {
    title: '6. MISE EN PLACE DE CHANTIER',
    description:
      'Déplacement sur chantier afin de guider vos équipes techniques pour le début du chantier',
    icon: faScrewdriverWrench,
  },
];
const StudySteps = () => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
    }, 8000);

    return () => clearTimeout(timer);
  }, [currentStep]);

  return (
    <div className="study-steps-container">
      <div className="steps-wrapper">
        {steps.map((step, index) => (
          <Step
                       title={step.title}
            description={step.description}
            isActive={index === currentStep}
            icon={step.icon}
            onClick={() => setCurrentStep(index)}
          />
        ))}
      </div>
    </div>
  );
};

const Step = ({  title, description, isActive, onClick, icon }) => {

  return (
    <div
      className={`step ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      <h2 className="stepTitle2">
        {title}{' '}
        <FontAwesomeIcon
          icon={icon}
          className="step-icon"
        />
      </h2>
      <div className="connector"></div>
      {isActive && <p>{description}</p>}
    </div>
  );
};

export default StudySteps;
