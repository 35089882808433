import React, { useState, useEffect } from 'react';

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(null); // 'up' or 'down'
  const [prevIndex, setPrevIndex] = useState(null);

  const handleNext = () => {
    setPrevIndex(currentIndex);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    setDirection('up');
  };

  const handlePrev = () => {
    setPrevIndex(currentIndex);
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
    setDirection('down');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 6000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="carousel-container">
      {images.map((image, index) => (
        <div
          key={index}
          className={`carousel-slide ${index === currentIndex ? 'active' : ''}`}
        >
          <img
            src={image}
            alt="carousel-item"
            className={`carousel-image ${
              index === currentIndex
                ? direction === 'up'
                  ? 'moving-in-up'
                  : 'moving-in-down'
                : index === prevIndex
                ? direction === 'up'
                  ? 'moving-out-up'
                  : 'moving-out-down'
                : ''
            }`}
            style={{
              display:
                index === currentIndex || index === prevIndex
                  ? 'block'
                  : 'none',
            }}
          />
        </div>
      ))}
      <div className="carouselBtnDiv">
        <button
          className="carouselBtn"
          onClick={handlePrev}
        >
          ↑
        </button>
        <button
          className="carouselBtn"
          onClick={handleNext}
        >
          ↓
        </button>
      </div>
    </div>
  );
};

export default Carousel;
