import Carousel from '../components/common/carousel';
import IndoorContent from '../components/indoor/indoor';
import indoor1 from '../images/indoor/indoor1.jpg';
import indoor2 from '../images/indoor/indoor2.jpg';
import indoor3 from '../images/indoor/indoor3.jpg';
import indoor4 from '../images/indoor/indoor4.jpg';

export default function Indoor() {
  const images = [indoor1, indoor2, indoor3, indoor4];

  return (
    <div className="indoorSection">
      <Carousel images={images} />
      <IndoorContent />
    </div>
  );
}
