import { Link } from 'react-router-dom';
import logo from '../../images/name_black_background_logo.png';

export default function Footer() {
  return (
    <footer className="footer">
      <img
        src={logo}
        alt="GeomExpert"
        className="logoFooter"
      ></img>
      <Link to="/mentionslegales">
        <p>Mentions Légales</p>
      </Link>
    </footer>
  );
}
