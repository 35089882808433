import React, { useState, useEffect, useRef } from 'react';

const HomeCarousel = ({ images, text }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(null); // 'up' or 'down'
  const [prevIndex, setPrevIndex] = useState(null);

  const handleNext = () => {
    setPrevIndex(currentIndex);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    setDirection('up');
  };

  const handlePrev = () => {
    setPrevIndex(currentIndex);
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
    setDirection('down');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 6000);

    return () => clearInterval(interval);
  }, [currentIndex]);
  const touchStartY = useRef(0);
  const touchEndY = useRef(0);

  const handleTouchStart = (event) => {
    touchStartY.current = event.touches[0].clientY;
  };

  const handleTouchEnd = () => {
    const deltaY = touchEndY.current - touchStartY.current;

    const swipeThreshold = 100;

    if (Math.abs(deltaY) < swipeThreshold) {
      return;
    }

    if (deltaY > 0) {
      handlePrev();
    } else {
      handleNext();
    }
  };
  return (
    <div
      className="carousel-container"
      onTouchStart={handleTouchStart}
      onTouchEnd={(event) => {
        touchEndY.current = event.changedTouches[0].clientY;
        handleTouchEnd();
      }}
    >
      {images.map((image, index) => (
        <div
          key={index}
          className={`carousel-slide ${index === currentIndex ? 'active' : ''}`}
        >
          <img
            src={image}
            alt="carousel-item"
            className={`carousel-image ${
              index === currentIndex
                ? direction === 'up'
                  ? 'moving-in-up'
                  : 'moving-in-down'
                : index === prevIndex
                ? direction === 'up'
                  ? 'moving-out-up'
                  : 'moving-out-down'
                : ''
            }`}
            style={{
              display:
                index === currentIndex || index === prevIndex
                  ? 'block'
                  : 'none',
            }}
          />
          <div className="carousel-overlay"></div>
          <div
            className={`carousel-text ${
              index === currentIndex
                ? direction === 'up'
                  ? 'moving-in-up'
                  : 'moving-in-down'
                : index === prevIndex
                ? direction === 'up'
                  ? 'moving-out-up'
                  : 'moving-out-down'
                : ''
            }`}
          >
            <h3>{index === currentIndex ? text[index] : ''}</h3>
          </div>
        </div>
      ))}
      <div className="carouselBtnDiv">
        <button
          className="carouselBtn"
          onClick={handlePrev}
        >
          ↑
        </button>
        <button
          className="carouselBtn"
          onClick={handleNext}
        >
          ↓
        </button>
      </div>
    </div>
  );
};

export default HomeCarousel;
