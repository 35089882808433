import Carousel from '../components/common/carousel';
import OutdoorContent from '../components/outdoor/outdoorcomponent';
import outdoor1 from '../images/outdoor/outdoor1.jpg';
import outdoor2 from '../images/outdoor/outdoor2.jpg';
import outdoor3 from '../images/outdoor/outdoor3.jpg';
import outdoor4 from '../images/outdoor/outdoor4.jpg';
export default function Outdoor() {
  const images = [outdoor1, outdoor2, outdoor3, outdoor4];
  return (
    <div className="indoorSection">
      <Carousel images={images} />
      <OutdoorContent />
    </div>
  );
}
